import React from 'react';

const BenefitCard: React.FC<{ title: string; description: string; imageSrc: string }> = ({ title, description, imageSrc }) => {
  return (
    <div className="flex gap-2.5 justify-center items-center self-stretch py-9 pr-7 pl-8 my-auto text-white rounded-3xl bg-zinc-800 min-h-[490px] min-w-[240px] w-[450px] max-md:px-5 max-md:max-w-full">
      <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[391px]">
        <div className="flex flex-col w-full">
          <h3 className="text-2xl font-semibold leading-tight">{title}</h3>
          <p className="mt-5 text-lg leading-7">{description}</p>
        </div>
        <div className="flex flex-col mt-10 w-full text-lg font-medium leading-none max-md:mt-10">
          <img loading="lazy" src={imageSrc} alt={title} className="object-contain w-full rounded-xl aspect-[2.21]" />
          <div className="flex flex-col mt-2.5 w-full rounded-xl">
            <button className="overflow-hidden gap-2 self-stretch px-6 py-4 rounded-xl min-h-[56px] max-md:px-5">
              Hire an AI Expert
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CustomerBenefits: React.FC = () => {
  return (
    <section className="flex flex-col mt-36 w-full max-w-[1170px] max-md:mt-10 max-md:max-w-full">
      <h2 className="text-6xl font-medium leading-none text-center text-zinc-800 max-md:max-w-full max-md:text-4xl">
        Customer Benefits
      </h2>
      <div className="flex flex-wrap gap-8 justify-between items-center mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <BenefitCard
          title="Verified Experts"
          description="We thoroughly verify all specialists, ensuring high qualifications and reliability. Each questionnaire is reviewed manually"
          imageSrc="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/63a76467bee5523167f1946a836fbababf9ffea34f12bbd67838d888d466c88f?apiKey=78749fe2f6d24633bc343f56dc548523&"
        />
        <div className="flex flex-col self-stretch my-auto min-w-[240px] text-zinc-800 w-[690px] max-md:max-w-full">
          <div className="flex flex-col max-w-full w-[690px]">
            <div className="flex relative flex-col items-start px-10 py-12 w-full bg-gray-100 rounded-3xl min-h-[230px] max-md:px-5">
              <div className="flex z-0 flex-col max-w-full w-[410px]">
                <h3 className="text-2xl font-semibold leading-tight">No hidden fees here!</h3>
                <p className="mt-5 text-lg leading-7">
                  You only pay a fixed amount for the number of contacts you need. It's that simple and straightforward!
                </p>
              </div>
              <img 
                loading="lazy" 
                src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/2af8109666f94fcf570db901c2aa51f5c3402529f9fef5631ded393391ec1456?apiKey=78749fe2f6d24633bc343f56dc548523&" 
                alt="No hidden fees illustration" 
                className="object-contain absolute right-0 bottom-0 z-0 max-w-full aspect-[1.23] h-[230px] w-[284px]" 
              />
            </div>
          </div>
          <div className="flex flex-col px-10 pt-10 pb-20 mt-8 max-w-full bg-white rounded-3xl border-2 border-red-600 border-solid min-h-[230px] w-[690px] max-md:px-5">
            <div className="flex flex-col w-full">
              <h3 className="text-2xl font-semibold leading-tight max-md:max-w-full">Convenient Search</h3>
              <p className="mt-5 text-lg leading-7 max-md:max-w-full">
                An intuitive interface allows you to quickly find the right AI specialist with the necessary skills.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomerBenefits;