import React from 'react';

const UnlockAIPower: React.FC = () => {
  return (
    <section className="flex flex-col mt-36 w-full max-w-[1410px] max-md:mt-10 max-md:max-w-full">
      <h2 className="self-center text-6xl font-medium text-center leading-[64px] text-zinc-800 w-[834px] max-md:max-w-full max-md:text-4xl max-md:leading-[51px]">
        Unlock the Power of AI Expertise with Our Directory
      </h2>
      <div className="flex flex-wrap gap-4 justify-between items-center mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col self-stretch my-auto min-h-[655px] min-w-[240px] w-[825px] max-md:max-w-full">
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/c530ed5f-32f3-47ed-a406-c84585ec30cf?apiKey=78749fe2f6d24633bc343f56dc548523&" 
            alt="AI Expert Directory visualization" 
            className="object-contain max-w-full rounded-3xl aspect-[1.26] w-[825px]"
          />
        </div>
        <div className="flex gap-2.5 items-center self-stretch px-9 py-10 my-auto text-white rounded-3xl bg-zinc-800 min-h-[655px] min-w-[240px] w-[570px] max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[500px]">
            <div className="flex flex-col w-full max-md:max-w-full">
              <div className="flex flex-col w-full font-semibold max-md:max-w-full">
                <h3 className="text-base leading-7 max-md:max-w-full">STEP 1</h3>
                <h4 className="mt-5 text-5xl leading-[52px] max-md:max-w-full">
                  <span className="font-medium">Explore our </span>
                  <span className="font-medium">AI Expert Directory</span>
                </h4>
              </div>
              <p className="mt-8 text-lg leading-6 max-md:max-w-full">
                Review detailed profiles of our vetted AI specialists. Browse their backgrounds, skills, case studies, and client ratings to find the perfect fit for your project.
              </p>
            </div>
            <div className="flex flex-col mt-10 w-full font-semibold max-md:mt-10 max-md:max-w-full">
              <hr className="w-full min-h-0 border border-solid border-zinc-600 max-md:max-w-full" />
              {['Explore our AI Expert Directory', 'Unlock Access', 'Collaborate Seamlessly'].map((step, index) => (
                <React.Fragment key={index}>
                  <div className="flex flex-col mt-5 w-full max-md:max-w-full">
                    <h3 className="text-base leading-7">STEP {index + 2}</h3>
                    <h4 className="text-2xl leading-tight max-md:max-w-full">{step}</h4>
                  </div>
                  {index < 2 && <hr className="mt-5 w-full min-h-0 border border-solid border-zinc-600 max-md:max-w-full" />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnlockAIPower;