import React from 'react';

interface ExpertCardProps {
  name: string;
  rating: string;
  reviews: string;
  country: string;
  imageUrl: string;
}

const ExpertCard: React.FC<ExpertCardProps> = ({ name, rating, reviews, country, imageUrl }) => {
  return (
    <div className="flex relative grow shrink gap-2.5 justify-center items-start self-stretch px-5 py-6 my-auto min-h-[388px] min-w-[240px] w-[264px]">
      <div className="flex absolute inset-0 z-0 shrink-0 self-start bg-white rounded-xl border-2 border-gray-100 border-solid h-[388px] min-w-[240px] w-[330px]" />
      <div className="flex z-0 flex-col my-auto min-w-[240px] w-[289px]">
        <div className="flex flex-col w-full">
          <div className="flex flex-col self-center w-20">
            <div className="flex flex-col w-full">
              <img loading="lazy" src={imageUrl} alt={name} className="object-contain w-full aspect-square" />
              <div className="flex flex-col self-center mt-2.5 w-full max-w-[70px]">
                <div className="text-xl font-semibold tracking-wide leading-snug text-center text-zinc-800">
                  {name}
                </div>
                <div className="flex gap-1 items-center w-full text-xs leading-loose whitespace-nowrap">
                  <div className="flex gap-1 items-center self-stretch my-auto text-right text-zinc-800">
                    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/dfd5751e1f8d6e37e29382b3da1eb75aff453bb3a65c0996e32e700f7c6f76a5?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="Star rating" className="object-contain shrink-0 self-stretch my-auto w-4 aspect-square" />
                    <div className="self-stretch my-auto">{rating}</div>
                  </div>
                  <div className="self-stretch my-auto text-zinc-500">({reviews})</div>
                </div>
              </div>
            </div>
            <div className="mt-1 text-sm leading-none text-center text-zinc-500">{country}</div>
          </div>
          <div className="flex flex-col mt-4 w-full text-center">
            <div className="text-base leading-6 text-zinc-800">
              I will fine tune a large language model for your specific needs
            </div>
            <div className="flex gap-2 items-center self-center mt-3 text-sm leading-none text-zinc-500">
              <div className="flex relative gap-2.5 justify-center items-start self-stretch px-3 py-1 my-auto min-h-[28px]">
                <div className="flex absolute inset-0 z-0 shrink-0 self-start h-7 bg-gray-100 rounded-2xl w-[89px]" />
                <div className="z-0 my-auto">AI chatbot</div>
              </div>
              <div className="flex relative gap-2.5 justify-center items-start self-stretch px-3 py-1 my-auto min-h-[28px]">
                <div className="flex absolute inset-0 z-0 shrink-0 self-start h-7 bg-gray-100 rounded-2xl w-[117px]" />
                <div className="z-0 my-auto">AI applications</div>
              </div>
            </div>
          </div>
        </div>
        <button className="overflow-hidden gap-2 self-center px-4 py-3.5 mt-6 text-lg font-medium leading-none text-center text-white whitespace-nowrap rounded-xl bg-zinc-800 min-h-[50px]">
          Contact
        </button>
      </div>
    </div>
  );
};

const TopExperts: React.FC = () => {
  const experts = [
    { name: "Tom A.", rating: "4.42", reviews: "25", country: "UK", imageUrl: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/0f4d1f7389d7fc1a67971e45cd6f2ac22ae7aeb8041eb494e2eb502532da4aa0?apiKey=78749fe2f6d24633bc343f56dc548523&" },
    { name: "atomate.ai", rating: "4.46", reviews: "256", country: "USA", imageUrl: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/3ac1f637e7a7b9533e8ef726bc87f74c9d655bf77cc37cfb94465811ce1a7982?apiKey=78749fe2f6d24633bc343f56dc548523&" },
    { name: "Lina", rating: "4.42", reviews: "25", country: "UK", imageUrl: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/172413b8bbfde8a5b31dbaf0536e8b851a966c8e79f06b77749b8c330f1f09db?apiKey=78749fe2f6d24633bc343f56dc548523&" },
    { name: "Mary I.", rating: "4.46", reviews: "256", country: "USA", imageUrl: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/d7326c1306ebd7953dda6c8a6f5ece3c66f742cbcfa2a5c687c7fb403d31850a?apiKey=78749fe2f6d24633bc343f56dc548523&" }
  ];

  return (
    <section className="flex flex-col mt-1.5 w-full max-w-[1410px] max-md:max-w-full">
      <div className="flex flex-col self-center max-w-full text-center text-zinc-800 w-[695px]">
        <div className="flex flex-col w-full max-md:max-w-full">
          <h2 className="text-6xl font-medium leading-none max-md:max-w-full max-md:text-4xl">
            Explore Top Expert
          </h2>
          <p className="mt-5 text-lg leading-7 max-md:max-w-full">
            In our directory, you'll find a mix of freelancers and agencies, so you can pick the option that works best for you!
          </p>
        </div>
        <div className="flex flex-wrap gap-3 items-center self-center mt-10 text-base max-md:max-w-full">
          Continuing from where we left off:

          <button className="flex relative gap-1.5 justify-center items-start self-stretch py-2 pr-4 pl-3.5 my-auto text-white rounded-2xl min-h-[38px] w-[130px]">
            <div className="flex absolute inset-0 z-0 shrink-0 self-start rounded-3xl border border-solid bg-zinc-800 border-zinc-800 h-[38px] w-[130px]" />
            <span className="z-0 my-auto">All specialists</span>
          </button>
          {['AI applications', 'AI chatbot', 'AI applications', 'AI applications'].map((tag, index) => (
            <button key={index} className="flex relative gap-1.5 justify-center items-start self-stretch py-2 pr-3.5 pl-3 my-auto rounded-2xl min-h-[38px] w-[136px]">
              <div className="flex absolute inset-y-0 -inset-x-px z-0 shrink-0 self-start bg-white rounded-3xl border border-solid border-zinc-800 h-[38px] w-[138px]" />
              <span className="z-0 my-auto">{tag}</span>
            </button>
          ))}
        </div>
      </div>
      <div className="flex flex-wrap gap-8 items-center mt-16 w-full max-md:mt-10 max-md:max-w-full">
        {experts.map((expert, index) => (
          <ExpertCard key={index} {...expert} />
        ))}
      </div>
    </section>
  );
};

export default TopExperts;