import React, { useState, useEffect } from 'react';
import ButtonLink from '../elements/ButtonLink';
import MainMenu from '../components/MainMenu';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuItems] = useState([]);

  // const [menuItems, setMenuItems] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);

  // Fetch menu items from WordPress
  useEffect(() => {
    const fetchMenuItems = async () => {
      // Temporarily disable API call until WordPress endpoint is ready
      return;
      
      /* 
      setIsLoading(true);
      try {
        const response = await fetch('/wp-json/wp/v2/menu-items');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setMenuItems(data);
      } catch (error) {
        console.error('Error fetching menu items:', error);
        setMenuItems([]); // Use empty array to trigger default menu items
      } finally {
        setIsLoading(false);
      }
      */
    };

    fetchMenuItems();
  }, []);

  // Handle body overflow when menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isMenuOpen]);

  return (
    <header className="main-header container flex w-full mx-auto py-6 md:py-4 px-4">
      <nav className="flex justify-between w-full relative z-10 flex-auto gap-10 items-center my-auto mr-0 text-base max-lg:max-w-full">

        <div className="main-header-logo z-0 self-stretch my-auto text-2lg font-semibold leading-none text-left text-black xl:w-[340px]">
          aibyhumans.com
        </div>

        {/* Desktop Menu */}
        <div className="main-header-menu xl:absolute xl:left-1/2 xl:-translate-x-1/2 z-0 gap-5 xl:gap-10 items-center hidden lg:flex">
          <MainMenu items={menuItems} />
        </div>

        {/* Desktop Buttons */}
        <div className="main-header-buttons z-0 gap-3 items-center self-stretch my-auto font-medium xl:w-[340px] hidden lg:flex lg:justify-end">
          <ButtonLink id="join-expert" variant="outlined" href="#join-expert">Join as an Expert</ButtonLink>
          <ButtonLink id="get-full-access" variant="default" href="#get-full-access">Get Full Access</ButtonLink>
        </div>

        {/* Mobile Menu Button */}
        <button 
          className="shrink-0 block lg:hidden flex-col justify-center items-center w-5 h-5 relative z-[60]"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {!isMenuOpen ? (
            <>
              <span className="block w-5 h-0.5 bg-black mb-1"></span>
              <span className="block w-5 h-0.5 bg-black mb-1"></span>
              <span className="block w-5 h-0.5 bg-black"></span>
            </>
          ) : (
            <svg className="w-5 h-5" fill="none" viewBox="0 0 20 20" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.8} d="M2 2L18 18M2 18L18 2" />
            </svg>
          )}
        </button>

        {/* Mobile Menu Overlay */}
        <div 
          className={`fixed inset-0 bg-white z-50 lg:hidden transition-transform duration-300 ease-in-out ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex flex-col items-center justify-between h-full space-y-8 pt-16 pb-8">
            <div className="flex flex-col space-y-4 w-full px-8">
              <MainMenu 
                items={menuItems} 
                isMobile={true}
                onItemClick={() => setIsMenuOpen(false)}
              />
            </div>
            <div className="flex flex-col space-y-4 w-full px-8">
              <ButtonLink id="join-expert" variant="outlined" href="#join-expert" onClick={() => setIsMenuOpen(false)}>
                Join as an Expert
              </ButtonLink>
              <ButtonLink id="get-full-access" variant="default" href="#get-full-access" onClick={() => setIsMenuOpen(false)}>
                Get Full Access
              </ButtonLink>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;