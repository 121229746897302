import React from 'react';

interface MenuItem {
  id: number;
  title: string;
  url: string;
}

interface MainMenuProps {
  items: MenuItem[];
  isMobile?: boolean;
  onItemClick?: () => void;
}

const defaultMenuItems = [
  {
    id: 1,
    title: 'About',
    url: '#about'
  },
  {
    id: 2,
    title: 'How to Hire',
    url: '#how-to-hire'
  },
  {
    id: 3,
    title: 'Become an AI Expert',
    url: '#become-an-ai-expert'
  },
  {
    id: 4,
    title: 'FAQs',
    url: '#faqs'
  }
];

const MainMenu: React.FC<MainMenuProps> = ({ items, isMobile = false, onItemClick }) => {
  // Use default items if no items provided
  const menuItems = items?.length ? items : defaultMenuItems;

  return (
    <>
      {menuItems.map((item) => (
        <a
          key={item.id}
          href={item.url}
          onClick={onItemClick}
          className={`gap-1 self-stretch my-auto hover:text-primary  transition-colors ${
            isMobile ? 'text-xl w-full py-2' : 'whitespace-nowrap'
          }`}
        >
          {item.title}
        </a>
      ))}
    </>
  );
};

export default MainMenu; 