import { WP_CONFIG } from './config';
import { ApiResponse, WordPressPost, WordPressCategory, FormattedPostPreview, FormattedPost } from './types';
import { formatPostPreview, formatPost } from './formatters';

export class WordPressService {
  private static async fetchApi(endpoint: string, params: Record<string, string> = {}): Promise<Response> {
    const queryParams = new URLSearchParams(params).toString();
    const url = `${WP_CONFIG.BASE_URL}${endpoint}${queryParams ? `?${queryParams}` : ''}`;
    
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    return response;
  }

  static async fetchPosts(page: number, perPage: number): Promise<ApiResponse<FormattedPostPreview[]>> {
    try {
      const response = await this.fetchApi(WP_CONFIG.ENDPOINTS.POSTS, {
        [WP_CONFIG.PARAMS.PAGE]: page.toString(),
        [WP_CONFIG.PARAMS.PER_PAGE]: perPage.toString(),
        [WP_CONFIG.PARAMS.EMBED]: ''
      });

      const totalPages = parseInt(response.headers.get('X-WP-TotalPages') || '1');
      const posts: WordPressPost[] = await response.json();

      return {
        data: posts.map(formatPostPreview),
        error: null,
        totalPages
      };
    } catch (error) {
      console.error('Error fetching posts:', error);
      return {
        data: null,
        error: error instanceof Error ? error.message : 'An error occurred while fetching posts',
        totalPages: 1
      };
    }
  }

  static async fetchSinglePost(slug: string): Promise<ApiResponse<FormattedPost>> {
    try {
      const response = await this.fetchApi(WP_CONFIG.ENDPOINTS.POSTS, {
        slug,
        [WP_CONFIG.PARAMS.EMBED]: ''
      });

      const posts: WordPressPost[] = await response.json();

      if (posts.length === 0) {
        throw new Error('Post not found');
      }

      return {
        data: formatPost(posts[0]),
        error: null
      };
    } catch (error) {
      console.error('Error fetching single post:', error);
      return {
        data: null,
        error: error instanceof Error ? error.message : 'An error occurred while fetching the post'
      };
    }
  }

  static async fetchCategories(): Promise<ApiResponse<WordPressCategory[]>> {
    try {
      const response = await this.fetchApi(WP_CONFIG.ENDPOINTS.CATEGORIES, {
        [WP_CONFIG.PARAMS.PER_PAGE]: '100'
      });

      const categories: WordPressCategory[] = await response.json();
      return {
        data: categories,
        error: null
      };
    } catch (error) {
      console.error('Error fetching categories:', error);
      return {
        data: null,
        error: error instanceof Error ? error.message : 'An error occurred while fetching categories'
      };
    }
  }

  static async fetchRelatedPosts(
    currentPostId: number,
    categories: number[],
    limit: number = 3
  ): Promise<ApiResponse<FormattedPostPreview[]>> {
    try {
      const response = await this.fetchApi(WP_CONFIG.ENDPOINTS.POSTS, {
        [WP_CONFIG.PARAMS.CATEGORIES]: categories.join(','),
        [WP_CONFIG.PARAMS.PER_PAGE]: '6',
        [WP_CONFIG.PARAMS.EMBED]: '',
        exclude: currentPostId.toString()
      });

      const posts: WordPressPost[] = await response.json();
      
      const shuffledPosts = posts
        .sort(() => Math.random() - 0.5)
        .slice(0, limit);

      return {
        data: shuffledPosts.map(formatPostPreview),
        error: null
      };
    } catch (error) {
      console.error('Error fetching related posts:', error);
      return {
        data: null,
        error: error instanceof Error ? error.message : 'An error occurred while fetching related posts'
      };
    }
  }
} 