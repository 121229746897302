import React from 'react';
import Header from '../components/Header';
import HomepageHero from '../sections/HomepageHero';
import TopExperts from '../sections/TopExperts';
import UnlockAIPower from '../sections/UnlockAIPower';
import CustomerBenefits from '../sections/CustomerBenefits';
import FlexiblePricing from '../sections/FlexiblePricing';
import BecomeAIExpert from '../sections/BecomeAIExpert';
import AICommunity from '../sections/AICommunity';
import UserTestimonial from '../sections/UserTestimonial';
import FAQSection from '../sections/FAQSection';
import HomepageJoinCTA from '../sections/HomepageJoinCTA';
import JoinCTA from '../sections/JoinCTA';
import Footer from '../components/Footer';

const HomePage: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen overflow-hidden bg-white">
      <Header />
      <HomepageHero />
      <TopExperts />
      <UnlockAIPower />
      <CustomerBenefits />
      <FlexiblePricing />
      <BecomeAIExpert />
      <AICommunity />
      <UserTestimonial />
      <FAQSection />
      <HomepageJoinCTA />
      <JoinCTA />
      <Footer />
    </div>
  );
};

export default HomePage;