import React from 'react';

const BlogPostSkeleton: React.FC = () => {
  return (
    <div className="animate-pulse">
      {/* Image skeleton */}
      <div className="rounded-md w-full h-52 lg:h-64 mb-4 bg-slate-100" />
      
      {/* Content skeleton */}
      <div>
        {/* Date skeleton */}
        <div className="h-4 bg-slate-100 rounded w-24 mb-3" />
        
        {/* Categories skeleton */}
        <div className="flex gap-2 mb-4">
          <div className="h-6 bg-slate-100 rounded w-16" />
          <div className="h-6 bg-slate-100 rounded w-20" />
        </div>
        
        {/* Title skeleton */}
        <div className="space-y-2">
          <div className="h-5 bg-slate-100 rounded w-full" />
          <div className="h-5 bg-slate-100 rounded w-3/4" />
        </div>
      </div>
    </div>
  );
};

export default BlogPostSkeleton; 