import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="container flex flex-col justify-center self-stretch px-4 py-8 md:py-5 w-full bg-white min-h-[70px] max-md:max-w-full">
      <div className="flex flex-col md:flex-row gap-4 md:gap-10 justify-between items-center w-full max-w-[1410px] max-md:max-w-full">
        <div className="self-stretch my-auto text-xl font-semibold leading-none text-center">
          aibyhumans.com
        </div>
        <nav className="flex flex-col md:flex-row gap-2 md:gap-10 self-stretch my-auto text-base min-w-[240px] max-md:max-w-full">
          <a href="#blog" className="self-stretch my-auto text-center md:text-left">Blog</a>
          <a href="#privacy" className="self-stretch my-auto text-center md:text-left">Privacy Policy</a>
          <a href="#terms" className="self-stretch my-auto text-center md:text-left">Terms of Service</a>
          <a href="#cookies" className="self-stretch my-auto text-center md:text-left">Cookie Preference</a>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;