import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbItem {
  label: string;
  url?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  return (
    <div className="breadcrumbs py-4">
      <div className="text-sm">
        {items.map((item, index) => {
          const isLast = index === items.length - 1;

          if (isLast) {
            return (
              <span key={item.label} className="text-gray-900">
                {item.label}
              </span>
            );
          }

          return (
            <React.Fragment key={item.label}>
              {item.url ? (
                <Link to={item.url} className="text-gray-600 hover:text-primary">
                  {item.label}
                </Link>
              ) : (
                <span className="text-gray-600">{item.label}</span>
              )}
              <span className="mx-2">/</span>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default Breadcrumbs; 