import React from 'react';
import { Link } from 'react-router-dom';
import ButtonLink from '../elements/ButtonLink';
import { useCategories } from '../hooks/useCategories';
import type { FormattedPostPreview } from '../api/wordpress/types';

interface BlogPostProps extends FormattedPostPreview {}

const BlogPost: React.FC<BlogPostProps> = ({ 
  title, 
  categories = [], 
  date, 
  image, 
  url 
}) => {
  const { categories: wpCategories, loading, error } = useCategories();

  if (loading || error || !categories.length) {
    return (
      <div className="blog-post">
        <Link to={url} className="block blog-post-image rounded-md overflow-hidden w-full h-52 lg:h-64 mb-4">
          <img 
            src={image} 
            alt={title}
            className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
          />
        </Link>
        <div className="blog-post-content">
          <div className="blog-post-date text-slate-500 text-sm md:text-base mb-3 md:mb-[18px]">{date}</div>
          <Link to={url} className="block hover:text-gray-600">
            <h2 className="blog-post-title text-lg font-semibold">{title}</h2>
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="blog-post">
      <Link to={url} className="block blog-post-image rounded-md overflow-hidden w-full h-52 lg:h-64 mb-4">
        <img 
          src={image} 
          alt={title}
          className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
        />
      </Link>
      <div className="blog-post-content">
        {categories.length > 0 && wpCategories.length > 0 && (
          <div className="flex flex-wrap items-center gap-2 mb-2 md:mb-4">
            {categories.map((categoryId) => {
              const category = wpCategories.find(cat => cat.id === categoryId);
              return category && (
                <ButtonLink
                  key={category.id}
                  as="link"
                  href={`/blog/category/${category.slug}`}
                  variant="tag"
                  size="small"
                >
                  {category.name}
                </ButtonLink>
              );
            })}
          </div>
        )}
        <div className="blog-post-date text-slate-500 text-sm md:text-base mb-3 md:mb-[18px]">{date}</div>
        <Link to={url} className="block hover:text-gray-600">
          <h2 className="blog-post-title text-lg font-semibold mt-0">{title}</h2>
        </Link>
      </div>
    </div>
  );
};

export default BlogPost; 