import React from 'react';
import BlogPost from './BlogPost';
import BlogPostSkeleton from './BlogPostSkeleton';
import { useRelatedPosts } from '../hooks/useRelatedPosts';

interface RelatedPostsProps {
  postId: number;
  categories: number[];
}

const RelatedPosts: React.FC<RelatedPostsProps> = ({ postId, categories }) => {
  const { posts, loading, error } = useRelatedPosts(postId, categories);
  const RELATED_POSTS_COUNT = 3;

  if (loading) {
    return (
      <div className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {[...Array(RELATED_POSTS_COUNT)].map((_, index) => (
            <BlogPostSkeleton key={index} />
          ))}
        </div>
      </div>
    );
  }

  if (error || !posts.length) {
    return null;
  }

  return (
    <div className="mb-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {posts.map(post => (
          <BlogPost 
            key={post.id}
            {...post}
          />
        ))}
      </div>
    </div>
  );
};

export default RelatedPosts; 