import React from 'react';

const BlogPostSingleSkeleton: React.FC = () => {
  return (
    <article className="container mx-auto px-4 max-w-[67ch] pt-4">
      <div className="animate-pulse">
        {/* Breadcrumbs skeleton */}
        <div className="flex gap-2 items-center">
          <div className="h-4 bg-slate-100 rounded w-12" />
          <div className="text-slate-300">/</div>
          <div className="h-4 bg-slate-100 rounded w-48" />
        </div>

        {/* Title skeleton */}
        <div className="mt-6 mb-3">
          <div className="h-12 bg-slate-100 rounded w-3/4 mb-2" />
          <div className="h-12 bg-slate-100 rounded w-1/2" />
        </div>
        
        {/* Date skeleton */}
        <div className="h-4 bg-slate-100 rounded w-32 mb-7" />

        {/* Image skeleton */}
        <div className="w-full h-[400px] bg-slate-100 rounded-lg mb-8" />

        {/* Content skeleton */}
        <div className="single-post-content prose prose-lg max-w-none mb-12 space-y-4">
          <div className="h-4 bg-slate-100 rounded w-full" />
          <div className="h-4 bg-slate-100 rounded w-full" />
          <div className="h-4 bg-slate-100 rounded w-3/4" />
          <div className="h-4 bg-slate-100 rounded w-full" />
          <div className="h-4 bg-slate-100 rounded w-5/6" />
        </div>

        {/* Author block skeleton */}
        <div className="bg-slate-100 px-9 py-7 mt-16 mb-12 rounded-lg">
          <div className="flex items-start gap-4 mb-2">
            <div className="w-[60px] h-[60px] rounded-full bg-slate-100" />
            <div className="flex-1">
              <div className="h-6 bg-slate-100 rounded w-48 mb-1" />
              <div className="h-4 bg-slate-100 rounded w-32 mb-3" />
            </div>
          </div>
          <div className="space-y-2">
            <div className="h-4 bg-slate-100 rounded w-full" />
            <div className="h-4 bg-slate-100 rounded w-5/6" />
          </div>
        </div>
      </div>
    </article>
  );
};

export default BlogPostSingleSkeleton;