import { useState, useEffect } from 'react';
import { WordPressService } from '../api';
import type { WordPressCategory } from '../api/wordpress/types';

interface UseCategoriesResult {
  categories: WordPressCategory[];
  loading: boolean;
  error: string | null;
}

export const useCategories = (): UseCategoriesResult => {
  const [categories, setCategories] = useState<WordPressCategory[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadCategories = async () => {
      const result = await WordPressService.fetchCategories();
      
      if (result.error) {
        setError(result.error);
        setCategories([]);
      } else if (result.data) {
        setCategories(result.data);
      }
      
      setLoading(false);
    };

    loadCategories();
  }, []);

  return { categories, loading, error };
}; 