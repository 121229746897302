import { WordPressPost, FormattedPost, FormattedPostPreview } from './types';

export const formatPostPreview = (post: WordPressPost): FormattedPostPreview => {
  return {
    id: post.id,
    title: post.title.rendered,
    categories: post.categories,
    date: new Date(post.date).toLocaleDateString('en-US', { 
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    }),
    image: post._embedded?.['wp:featuredmedia']?.[0]?.source_url || 
           `${process.env.PUBLIC_URL}/blog/default-blog.jpg`,
    url: `/blog/${post.slug}`,
  };
};

export const formatPost = (post: WordPressPost): FormattedPost => {
  return {
    ...formatPostPreview(post),
    content: post.content.rendered,
    excerpt: post.excerpt.rendered,
    imageAlt: post._embedded?.['wp:featuredmedia']?.[0]?.alt_text || post.title.rendered,
    author: {
      name: post._embedded?.author?.[0]?.name || 'Anonymous',
      position: 'AI Professional',
      image: post._embedded?.author?.[0]?.avatar_urls?.['96'] || 
             `${process.env.PUBLIC_URL}/default-avatar.png`,
      bio: post._embedded?.author?.[0]?.description || 'No biography available'
    }
  };
}; 