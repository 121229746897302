import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { WordPressService } from '../api';
import type { FormattedPost } from '../api/wordpress/types';
import Header from '../components/Header';
import Footer from '../components/Footer';
import JoinCTA from '../sections/JoinCTA';
import Breadcrumbs from '../components/Breadcrumbs';
import RelatedPosts from '../components/RelatedPosts';
import BlogPostSingleSkeleton from '../components/BlogPostSingleSkeleton';

const BlogPostSingle: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<FormattedPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadPost = async () => {
      if (!slug) return;
      
      setLoading(true);
      setError(null);
      
      const result = await WordPressService.fetchSinglePost(slug);
      
      if (result.error) {
        setError(result.error);
        setPost(null);
      } else if (result.data) {
        setPost(result.data);
      }
      
      setLoading(false);
    };

    loadPost();
  }, [slug]);

  if (loading) {
    return (
      <div className="flex flex-col min-h-screen bg-white">
        <Header />
        <BlogPostSingleSkeleton />
        <Footer />
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="flex flex-col min-h-screen bg-white">
        <Header />
        <div className="container mx-auto px-4 py-8 flex justify-center items-center flex-grow">
          <div className="text-red-600">Error: {error || 'Post not found'}</div>
        </div>
        <Footer />
      </div>
    );
  }

  const breadcrumbItems = [
    { label: 'Blog', url: '/blog' },
    { label: post.title }
  ];

  return (
    <div className="flex flex-col min-h-screen bg-white">

      <Header />

      <article className="container mx-auto px-4 lg:max-w-[67ch] pt-4">

        <Breadcrumbs items={breadcrumbItems} />

        <h1 className="text-4xl md:text-6xl font-bold leading-extra-tight mt-6 mb-3"
            dangerouslySetInnerHTML={{ __html: post.title }} />
        
        <div className="text-slate-500 mb-7">
          {post.date}
        </div>

        <img 
          src={post.image} 
          alt={post.imageAlt}
          className="w-full h-[400px] object-cover rounded-lg mb-8"
        />

        <div 
          className="single-post-content prose prose-lg max-w-none mb-12"
          dangerouslySetInnerHTML={{ __html: post.content }}
        />

        {/* Author Block */}
        <div className="bg-slate-100 px-9 py-7 mt-16 mb-12 rounded-lg">
          <div className="flex items-start gap-4 mb-2">
            <img 
              src={post.author.image}
              alt={post.author.name}
              className="w-[60px] h-[60px] rounded-full object-cover"
            />
            <div>
              <h3 className="text-xl leading-tight font-bold mt-0 mb-1">{post.author.name}</h3>
              <p className="text-slate-500 mb-3">{post.author.position}</p>
            </div>
          </div>
          <div className="text-base text-black" dangerouslySetInnerHTML={{ __html: post.author.bio }}/>
        </div>
      </article>

      <div className="related-posts container mx-auto px-4 mt-8">
        <h2 className="text-3xl md:text-4xl lg:text-[44px] font-bold leading-tight mb-10">Related articles</h2>

        <RelatedPosts postId={post.id} categories={post.categories} />
      </div>

      <JoinCTA />
      <Footer />
    </div>
  );
};

export default BlogPostSingle; 