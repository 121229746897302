import React from 'react';
import classNames from 'classnames';

type BaseButtonLinkProps = {
  variant?: 'default' | 'outlined' | 'inverted' | 'tag' | 'rainbow';
  size?: 'default' | 'small' | 'big';
  fullWidth?: boolean;
  children: React.ReactNode;
};

type ButtonLinkAsButton = BaseButtonLinkProps &
  Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseButtonLinkProps> & {
    as?: 'button';
  };

type ButtonLinkAsLink = BaseButtonLinkProps &
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof BaseButtonLinkProps> & {
    as: 'link';
    href: string;
  };

type ButtonLinkProps = ButtonLinkAsButton | ButtonLinkAsLink;

const ButtonLink: React.FC<ButtonLinkProps> = ({
  variant = 'default',
  size = 'default',
  id = '',
  fullWidth = false,
  children,
  className,
  as = 'button',
  ...props
}) => {
  const buttonClasses = classNames(
    // Base styles
    'inline-flex items-center justify-center',
    'text-base font-medium leading-5',
    'rounded',
    'border',
    'transition-all duration-200',
    'focus:outline-none focus:ring-2 focus:ring-offset-2',
    'cursor-pointer',
    
    // Size styles
    {
      'px-3 py-1 text-sm': size === 'small',
      'px-5 py-2 text-base': size === 'default',
      'px-6 py-[18px] text-lg': size === 'big',
    },
    
    // Width control
    fullWidth ? 'w-full' : 'w-auto',
    
    // Variant specific styles
    {
      // Default variant
      'bg-black text-white hover:bg-opacity-90 focus:ring-black border-black': 
        variant === 'default',
      
      // Outlined variant
      'border-black text-black hover:bg-black hover:text-white focus:ring-black': 
        variant === 'outlined',
      
      // Inverted variant
      'bg-white text-black hover:bg-gray focus:ring-white border-black': 
        variant === 'inverted',

      // Small variant
      'bg-slate-100 text-slate-500 hover:bg-slate-200 border-none rounded-xl':
        variant === 'tag',

      // Rainbow variant
      'bg-rainbow-gradient text-white border-none hover:opacity-90 focus:ring-red-500':
        variant === 'rainbow',

      // Link specific
      'no-underline': as === 'link',

      // Button specific
      'disabled:opacity-60 disabled:cursor-not-allowed': as === 'button',
    },
    
    className
  );

  if (as === 'link') {
    const { href, target, ...linkProps } = props as ButtonLinkAsLink;
    return (
      <a
        id={id}
        href={href}
        className={buttonClasses}
        target={target}
        rel={target === '_blank' ? 'noopener noreferrer' : undefined}
        {...linkProps}
      >
        {children}
      </a>
    );
  }

  return (
    <button 
      id={id}
      className={buttonClasses}
      {...(props as ButtonLinkAsButton)}
    >
      {children}
    </button>
  );
};

export default ButtonLink;