import { useState, useEffect } from 'react';
import { WordPressService } from '../api';
import type { FormattedPostPreview } from '../api/wordpress/types';

interface UseRelatedPostsResult {
  posts: FormattedPostPreview[];
  loading: boolean;
  error: string | null;
}

export const useRelatedPosts = (
  currentPostId: number,
  categories: number[],
  limit: number = 3
): UseRelatedPostsResult => {
  const [posts, setPosts] = useState<FormattedPostPreview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadRelatedPosts = async () => {
      if (!categories.length) {
        setPosts([]);
        setLoading(false);
        return;
      }

      setLoading(true);
      const result = await WordPressService.fetchRelatedPosts(currentPostId, categories, limit);
      
      if (result.error) {
        setError(result.error);
        setPosts([]);
      } else if (result.data) {
        setPosts(result.data);
      }
      
      setLoading(false);
    };

    loadRelatedPosts();
  }, [currentPostId, categories, limit]);

  return { posts, loading, error };
}; 