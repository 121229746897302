import React from 'react';

const FlexiblePricing: React.FC = () => {
  return (
    <section className="flex flex-col mt-36 w-full max-w-[1050px] max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-wrap gap-10 justify-between items-center w-full text-zinc-800 max-md:max-w-full">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[450px] max-md:max-w-full">
          <h2 className="text-5xl font-medium leading-[52px] max-md:max-w-full">
            Flexible Pricing - Pay for What You Need
          </h2>
          <p className="mt-5 text-lg leading-7 max-md:max-w-full">
            Our pricing is tailored to your requirements. Access expert contacts based on your budget. Pay only for the resources you'll actually utilize, without wasted spend. This model gives you granular control over your costs and lets you optimize your investment in AI talent.
          </p>
        </div>
        <img 
          loading="lazy" 
          src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/11b053caee2366df295830324c870cecf615ce3c96514b6f3202e23a8abbd245?apiKey=78749fe2f6d24633bc343f56dc548523&" 
          alt="Flexible pricing illustration" 
          className="object-contain self-stretch my-auto rounded-3xl aspect-[1.1] min-w-[240px] w-[450px] max-md:max-w-full" 
        />
      </div>
      <div className="flex flex-wrap gap-10 items-center self-start mt-36 max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col self-stretch my-auto text-sm whitespace-nowrap rounded-none min-w-[240px] w-[450px] max-md:max-w-full">
          <div className="flex relative flex-col px-7 pt-72 pb-6 w-full rounded-3xl min-h-[410px] max-md:px-5 max-md:pt-24 max-md:max-w-full">
            <img 
              loading="lazy" 
              src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/e4c75027ab0cf4d5730be0999b692dee8549e803e0c1742518e4ecd9a97bb1f5?apiKey=78749fe2f6d24633bc343f56dc548523&" 
              alt="AI talent search background" 
              className="object-cover absolute inset-0 size-full" 
            />
            <form className="flex relative gap-2.5 px-4 py-6 w-full rounded-xl bg-zinc-800">
              <input 
                type="search" 
                placeholder="Search..." 
                className="overflow-hidden flex-auto gap-1.5 self-stretch px-5 py-2.5 leading-loose bg-white rounded-lg min-h-[41px] text-zinc-500"
                aria-label="Search for AI experts"
              />
              <button 
                type="submit" 
                className="overflow-hidden gap-1.5 self-stretch py-3 pr-4 pl-5 font-medium leading-none text-white rounded-lg min-h-[41px]"
              >
                Search
              </button>
            </form>
          </div>
        </div>
        <div className="flex flex-col self-stretch my-auto min-w-[240px] text-zinc-800 w-[450px] max-md:max-w-full">
          <h2 className="text-5xl font-medium leading-none max-md:max-w-full">
            Access the AI Talent
          </h2>
          <p className="mt-5 text-lg leading-7 max-md:max-w-full">
            Stop wasting time on complex talent searches. Partner with us and unlock the AI expertise that will power your success.
            <br /><br />
            <strong>Key benefits:</strong>
          </p>
          <ul className="mt-2 text-lg leading-7 list-disc pl-5">
            <li>Pay for the expert contacts you need</li>
            <li>Avoid unnecessary spending</li>
            <li>Scalable access as your needs evolve</li>
            <li>Transparent and predictable pricing</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default FlexiblePricing;