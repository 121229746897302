import React, { useState } from 'react';

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col mt-8 w-full max-md:max-w-full">
      <button
        className="flex flex-col justify-center px-8 py-2 w-full bg-white rounded-2xl border border-solid border-zinc-800 min-h-[70px] max-md:px-5 max-md:max-w-full"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <div className="flex flex-wrap gap-7 items-center max-md:max-w-full">
          <h3 className="self-stretch my-auto text-lg font-medium leading-7 text-zinc-800 w-[458px] max-md:max-w-full">
            {question}
          </h3>
          <div className={`flex gap-1.5 justify-center items-center self-stretch px-1.5 my-auto w-6 h-6 rounded-md bg-zinc-800 border-zinc-800 min-h-[24px] transform ${isOpen ? 'rotate-180' : ''} transition-transform`}>
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/03c14d98e794835b88c17beab5178d2a08b8183c6a466e46cbd6454c156572a2?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="" className="object-contain self-stretch my-auto w-3.5 aspect-[1.17]" />
          </div>
        </div>
      </button>
      {isOpen && (
        <div className="px-8 py-4 mt-2 bg-white rounded-2xl border border-solid border-zinc-800">
          <p className="text-base leading-7 text-zinc-800">{answer}</p>
        </div>
      )}
    </div>
  );
};

const FAQSection: React.FC = () => {
  const faqs: FAQItemProps[] = [
    {
      question: "What services can I expect from AI specialists listed on your site?",
      answer: "Our AI specialists offer a wide range of services including AI chatbot development, machine learning model creation, natural language processing, computer vision applications, and AI strategy consulting. You can find experts specializing in various AI domains to meet your specific project needs."
    },
    {
      question: "How do I connect with an AI expert for my business needs?",
      answer: "To connect with an AI expert, simply browse our directory, filter based on your requirements, and view detailed profiles. Once you find a suitable expert, you can initiate contact through our platform to discuss your project further."
    },
    {
      question: "What types of AI specialists can I find on your platform?",
      answer: "Our platform hosts a diverse range of AI specialists including data scientists, machine learning engineers, NLP experts, computer vision specialists, AI researchers, and AI project managers. Whether you need someone for a specific technical task or broader AI strategy, you'll find the right expert here."
    },
    {
      question: "What are the benefits of using AI solutions for my business?",
      answer: "AI solutions can significantly enhance your business operations by automating tasks, providing data-driven insights, improving customer experiences, and enabling predictive analytics. This can lead to increased efficiency, reduced costs, and new revenue opportunities."
    },
    {
      question: "How does the rating system work for AI experts?",
      answer: "Our rating system is based on client feedback after completed projects. Experts are rated on factors such as expertise, communication, timeliness, and overall project success. This helps maintain high quality standards and allows you to make informed decisions when choosing an expert."
    }
  ];

  return (
    <section className="flex flex-col mt-36 w-full max-w-[1170px] max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col self-center max-w-full text-center text-zinc-800 w-[708px]">
        <h2 className="text-6xl font-medium leading-none max-md:max-w-full max-md:text-4xl">FAQs</h2>
        <p className="mt-5 text-lg leading-loose max-md:max-w-full">
          Got a question? We're here to answer! If you don't see your question here, <a href="#contact" className="font-medium underline">write to us</a>.
        </p>
      </div>
      <div className="flex flex-wrap gap-8 justify-between items-start mt-16 w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex flex-col min-w-[240px] w-[570px] max-md:max-w-full">
          {faqs.slice(0, 3).map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
        <div className="flex flex-col min-w-[240px] w-[570px] max-md:max-w-full">
          {faqs.slice(3).map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQSection;