import React from 'react';
import ButtonLink from '../elements/ButtonLink';

const JoinCTA: React.FC = () => {
  return (
    <section className="container px-4 mt-16 xl:mt-28 max-md:mt-10 mb-6 lg:mb-0">
     <div className="bg-black text-white flex flex-col md:flex-row relative font-medium rounded-lg overflow-hidden">
      <div className="w-full md:w-[45%] xl:w-[51%]">
        <img 
            loading="lazy" 
            src={`${process.env.PUBLIC_URL}/experts.jpg`} 
            alt="AI Expert illustration" 
            className="object-cover w-full h-full" 
          />
      </div>
      <div className="h-full w-full md:w-[55%] xl:w-[49%] flex flex-col px-8 py-10 xl:pt-[88px] xl:px-[70px] xl:pb-20">
        <h2 className="text-3xl leading-8 xl:text-[37px] xl:leading-10 text-white max-md:max-w-full mt-0 mb-8">Join aibyhumans.com today and start solving your business</h2>
        <div className="flex flex-col md:flex-row gap-4 md:gap-8">
          <ButtonLink href="/join" variant="rainbow" size="big">Get Full Access</ButtonLink>
          <ButtonLink href="/join" variant="inverted" size="big">Join as an AI Expert</ButtonLink>
        </div>
      </div>
     </div>
    </section>
  );
};

export default JoinCTA;