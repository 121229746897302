import React, { useState, useEffect, useRef } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import JoinCTA from '../sections/JoinCTA';
import BlogPost from '../components/BlogPost';
import BlogPostSkeleton from '../components/BlogPostSkeleton';
import Pagination from '../components/Pagination';
import { WordPressService } from '../api';
import type { FormattedPostPreview } from '../api/wordpress/types';

const BlogPage: React.FC = () => {
  const POSTS_PER_PAGE = 6;
  const blogGridRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [blogPosts, setBlogPosts] = useState<FormattedPostPreview[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState<string | null>(null);

  const scrollToGrid = () => {
    if (blogGridRef.current) {
      const yOffset = -100;
      const element = blogGridRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({
        top: y,
        behavior: 'smooth'
      });
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    setTimeout(scrollToGrid, 500);
  };

  useEffect(() => {
    const loadPosts = async () => {
      setIsLoading(true);
      const result = await WordPressService.fetchPosts(currentPage, POSTS_PER_PAGE);
      
      if (result.error) {
        setError(result.error);
        setBlogPosts([]);
      } else if (result.data) {
        setBlogPosts(result.data);
        setTotalPages(result.totalPages || 1);
      }
      
      setIsLoading(false);
    };

    loadPosts();
  }, [currentPage]);

  return (
    <div className="flex flex-col items-center justify-center overflow-hidden min-h-screen bg-white">
      <Header />
      
      {/* Blog Hero Section */}
      <div className="container mx-auto px-4 mb-4 lg:mb-12">
        <div className="blog-hero flex flex-col items-center justify-center w-full mx-auto px-4 py-24 md:pt-[105px] md:pb-24 bg-black text-white rounded-lg">
          <h1 className="text-4xl md:text-7xl leading-none font-medium mb-6">
            AI Pro Blog
          </h1>
          <p className="text-lg max-w-3xl text-center">
            Sharing insights, exploring trends, and uncovering the secrets to success in AI
          </p>
        </div>
      </div>

      {/* Blog Grid */}
      <div 
        ref={blogGridRef}
        id="blog-grid"
        className="blog-grid container mx-auto px-4 py-8 scroll-mt-24"
      >
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 md:gap-y-12 xl:gap-y-20">
            {[...Array(POSTS_PER_PAGE)].map((_, index) => (
              <BlogPostSkeleton key={index} />
            ))}
          </div>
        ) : error ? (
          <div className="text-red-600 text-center py-8">
            {error}
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 md:gap-y-12 xl:gap-y-20">
            {blogPosts.map(post => (
              <BlogPost key={post.id} {...post} />
            ))}
          </div>
        )}

        {/* Pagination */}
        {totalPages > 1 && !error && !isLoading && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
      </div>

      <JoinCTA />
      <Footer />
    </div>
  );
};

export default BlogPage; 