import React from 'react';

const HomepageJoinCTA: React.FC = () => {
  return (
    <section className="flex relative items-start self-start mt-36 font-medium max-md:mt-10">
      <div className="flex z-0 flex-col flex-1 shrink my-auto basis-0 min-w-[240px] max-md:max-w-full">
        <div className="flex relative flex-col py-20 pr-96 pl-96 w-full rounded-3xl bg-zinc-800 min-h-[342px] max-md:px-5 max-md:max-w-full">
          <div className="flex z-0 flex-col w-full max-md:max-w-full">
            <h2 className="text-4xl leading-10 text-center text-white max-md:max-w-full">
              Join aibyhumans.ai today and start solving your business
            </h2>
            <button className="overflow-hidden gap-2 self-center px-6 py-4 mt-8 text-lg leading-none bg-gray-100 rounded-xl min-h-[56px] text-zinc-800 max-md:px-5">
              Join as an AI Expert
            </button>
          </div>
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/30dac6f2885e757f8f2063cb7376f523efe812cfd7e629ebdeda2345209b5efd?apiKey=78749fe2f6d24633bc343f56dc548523&" 
            alt="AI Expert illustration" 
            className="object-contain absolute top-2/4 z-0 max-w-full -translate-y-2/4 aspect-[0.54] h-[407px] right-[-47px] translate-x-[0%] w-[251px]" 
          />
        </div>
      </div>
      <img 
        loading="lazy" 
        src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/cd2b76de4f7f6b76f00fdf1b9a72fdab0e0626d70a6e189eb6bc1e7ce30be681?apiKey=78749fe2f6d24633bc343f56dc548523&" 
        alt="AI Expert illustration" 
        className="object-contain absolute left-0 top-2/4 z-0 shrink-0 self-start -translate-y-2/4 aspect-[0.54] h-[407px] translate-x-[0%] w-[251px]" 
      />
    </section>
  );
};

export default HomepageJoinCTA;