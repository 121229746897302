import React from 'react';

interface BenefitProps {
  icon: string;
  title: string;
  description: string;
}

const Benefit: React.FC<BenefitProps> = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col grow shrink min-w-[240px] w-[216px]">
      <div className="flex gap-3 items-center self-start text-lg font-semibold leading-none">
        <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 self-stretch my-auto aspect-square w-[30px]" />
        <div className="self-stretch my-auto">{title}</div>
      </div>
      <p className="mt-5 text-base leading-6">{description}</p>
    </div>
  );
};

const BecomeAIExpert: React.FC = () => {
  const benefits: BenefitProps[] = [
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/26df7222cdad4d24cf991de150915c6bc8b69e527d922e69f2aeca7dcf3b677e?apiKey=78749fe2f6d24633bc343f56dc548523&",
      title: "Build Your Reputation",
      description: "Gain visibility through a comprehensive rating system, helping you establish credibility in the industry."
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/75d2a6d3-fcb1-4f68-8737-d75c439a56c2?apiKey=78749fe2f6d24633bc343f56dc548523&",
      title: "Free Start",
      description: "Kick off your journey by publishing your portfolio and responding to orders without any restrictions—it's completely free to get started!"
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/e16e202cc4749a245ae4b3dc655f929285456cdb355558a375e4b4485ee5e04a?apiKey=78749fe2f6d24633bc343f56dc548523&",
      title: "Receive Feedback",
      description: "Collect reviews from employers to improve your services and increase your chances of securing future projects."
    },
    {
      icon: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/bfbcfe4691f28078993c51b846c5716b141726fd3b105ab0041deb02b095d191?apiKey=78749fe2f6d24633bc343f56dc548523&",
      title: "Flexible Engagement",
      description: "Enjoy the freedom to set your own schedule and work from anywhere, allowing you to balance work and personal life effectively."
    }
  ];

  return (
    <section className="flex flex-col items-center mt-36 w-full max-w-[1169px] text-zinc-800 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col max-w-full text-center w-[765px]">
        <h2 className="text-6xl font-medium leading-none max-md:max-w-full max-md:text-4xl">
          Or Become an AI Expert
        </h2>
        <p className="mt-5 text-lg leading-loose max-md:max-w-full">
          Connect with businesses seeking your expertise. Advance your career with diverse projects.
        </p>
      </div>
      <div className="flex flex-wrap gap-8 items-start mt-16 w-full max-md:mt-10">
        {benefits.map((benefit, index) => (
          <Benefit key={index} {...benefit} />
        ))}
      </div>
    </section>
  );
};

export default BecomeAIExpert;