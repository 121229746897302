import React from 'react';

const UserTestimonial: React.FC = () => {
  return (
    <section className="flex relative flex-col items-start mt-36 w-full max-w-[1209px] min-h-[414px] max-md:mt-10 max-md:max-w-full">
      <div className="flex absolute right-0 bottom-0 z-0 flex-wrap gap-9 justify-between items-start w-[1209px] max-md:max-w-full">
        <div className="flex flex-wrap gap-4 items-center text-sm font-semibold leading-loose text-center whitespace-nowrap min-w-[240px] text-zinc-800 max-md:max-w-full">
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/58d0709f06f128bfd05a33ff50792e6346b10f514fa0a410bf665fb34b28d942?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="" className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" />
          <div className="flex flex-col self-stretch my-auto rounded-none min-w-[240px] w-[405px]">
            <div className="flex relative flex-col items-end px-10 pt-64 w-full rounded-2xl aspect-[1.446] max-md:pt-24 max-md:pl-5">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/190608911901df5134fca4ffd9020b0a7d428936b2a178f3c847f26de943f17e?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="User testimonial background" className="object-cover absolute inset-0 size-full" />
              <div className="relative px-5 py-1 bg-gray-100 rounded-none max-md:pr-5">
                aibyhumans.ai
              </div>
            </div>
          </div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/5b94c8912640b93cf681d059eabacea7c146355e0ecb96ff9a00cdd8bc495bd5?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="" className="object-contain shrink-0 self-stretch my-auto w-6 aspect-square" />
        </div>
        <div className="flex flex-col min-w-[240px] w-[690px] max-md:max-w-full">
          <p className="text-lg leading-7 text-zinc-800 max-md:max-w-full">
            OMG, guys, this directory is a game-changer! I was super stressed about finding an AI expert for my startup, but it turned out to be a breeze! And the support team? They're incredibly responsive - had a quick chat with them about some specific requirements, and they were so helpful.
            <br />
            No corporate robot responses, just real people helping real people.
            <br />
            10/10 would recommend to anyone in need of AI expertise! 🚀
          </p>
          <div className="flex flex-col mt-6 max-w-full w-[137px]">
            <div className="text-lg font-medium leading-loose text-zinc-800">
              Emily T.
            </div>
            <div className="mt-1 text-base text-zinc-500">
              Marketing Director
            </div>
          </div>
        </div>
      </div>
      <h2 className="absolute top-0 left-2/4 z-0 h-16 text-6xl font-medium leading-none text-center -translate-x-2/4 text-zinc-800 translate-y-[0%] w-[689px] max-md:max-w-full max-md:text-4xl">
        What Our Users Are Saying
      </h2>
    </section>
  );
};

export default UserTestimonial;