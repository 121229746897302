import React from 'react';

const AICommunity: React.FC = () => {
  return (
    <section className="flex flex-col mt-36 max-w-full text-center text-zinc-800 w-[692px] max-md:mt-10">
      <h2 className="text-6xl font-medium leading-none max-md:max-w-full max-md:text-4xl">
        Our AI Community
      </h2>
      <p className="mt-5 text-lg leading-7 max-md:max-w-full">
        We understand the challenges that both employers and AI experts face on other platforms. Join us on{" "}
        <a href="#discord" className="font-medium underline">Discord</a> to connect and collaborate!
      </p>
      <div className="mt-16 w-full max-w-[1170px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          {[1, 2, 3].map((columnIndex) => (
            <div key={columnIndex} className="flex flex-col w-[33%] max-md:ml-0 max-md:w-full">
              <div className={`flex flex-col px-6 py-12 mx-auto w-full rounded-xl min-h-[498px] text-zinc-800 max-md:px-5 max-md:mt-8 ${
                columnIndex === 1 ? 'bg-red-300' : columnIndex === 2 ? 'bg-amber-200' : 'bg-stone-400'
              }`}>
                {columnIndex === 1 && (
                  <>
                    <p className="text-lg leading-7">
                      Become part of a thriving network of AI professionals.
                    </p>
                    <div className="flex gap-4 items-start mt-12 w-80 max-w-full rounded-none max-md:mt-10">
                      <div className="flex flex-col flex-1 items-start self-start py-3.5 pr-7 pl-3.5 bg-white rounded-2xl border border-black border-solid max-md:pr-5">
                        <div className="flex gap-1.5 self-stretch text-xs font-medium uppercase">
                          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/57b71758e280a78a7d7956213eb194379b42dd0e7443054c108387115ea98a0a?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="" className="object-contain shrink-0 my-auto bg-blend-normal aspect-[1.4] w-[7px]" />
                          <div>TEXT CHANNELS</div>
                        </div>
                        {['gaming', 'main-chat', 'aibyhumans.ai'].map((channel, index) => (
                          <div key={index} className="flex gap-1 mt-2.5 whitespace-nowrap">
                            <div className="text-lg leading-none">#</div>
                            <div className="text-xs">{channel}</div>
                          </div>
                        ))}
                        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/aadbf19c00e10fd401977aaf7d0e6013c8ebf9bf9c507b32e920a2911e86ee30?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="" className="object-contain mt-20 w-11 aspect-square max-md:mt-10" />
                      </div>
                      <div className="flex flex-col flex-1 self-end mt-10">
                        <div className="flex gap-2 self-start whitespace-nowrap">
                          <div className="text-2xl leading-none">#</div>
                          <div className="text-lg basis-auto">aibyhumans.ai</div>
                        </div>
                        {[
                          { img: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/4c0d034dd5cb575e433c8eec1f0acf972c0da9fd38dbdf7810cf6756b424da06?apiKey=78749fe2f6d24633bc343f56dc548523&", text: "Hey! Did you hear that Midjourney got updated?" },
                          { img: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/83504a097512c7cfce47efbab0c82b97935a29650bee4b9b4f1fb08dee439b3d?apiKey=78749fe2f6d24633bc343f56dc548523&", text: "Oh, hey! When did that happen?" },
                          { img: "https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/4c0d034dd5cb575e433c8eec1f0acf972c0da9fd38dbdf7810cf6756b424da06?apiKey=78749fe2f6d24633bc343f56dc548523&", text: "yesterday" }
                        ].map((message, index) => (
                          <div key={index} className={`flex gap-1.5 px-2 ${index === 2 ? 'py-1.5' : 'pt-1.5 pb-3'} mt-1.5 text-xs ${index === 2 ? '' : 'leading-5'} bg-white rounded-2xl border border-black border-solid`}>
                            <img loading="lazy" src={message.img} alt="" className="object-contain shrink-0 self-start aspect-square w-[30px]" />
                            <div className={index === 2 ? "grow shrink my-auto w-[98px]" : ""}>{message.text}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}
                {columnIndex === 2 && (
                  <>
                    <p className="z-0 self-end text-lg leading-7">
                      Advance your career by working on diverse projects, earning competitive rates, and enjoying flexible work arrangements.
                    </p>
                    <div className="flex absolute right-6 z-0 gap-3.5 max-w-full h-28 rounded-none top-[172px] w-[321px]">
                      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/b68069c896da7747d24774a8b36077fc06c24fa89b874c041582913abbe5e056?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="" className="object-contain shrink-0 my-auto rounded-full aspect-square w-[50px]" />
                      <div className="flex z-10 flex-col grow shrink-0 basis-0 w-fit">
                        <div className="self-start ml-4 text-xs font-bold max-md:ml-2.5">Max Z.</div>
                        <div className="px-4 py-3 text-xs bg-white rounded-2xl border border-black border-solid">
                          Hello! Could you please tell me about the capabilities of your chatbot? We need to set up a bot for an online store to help with orders and answer customer questions.
                        </div>
                      </div>
                    </div>
                    <div className="flex absolute right-6 z-0 gap-3.5 max-w-full rounded-none bottom-[39px] h-[145px] w-[321px]">
                      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/6d5dc18ab3fd274205bb8b3122dddac539f6a873fc1284229acf3e25cb6de084?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="" className="object-contain shrink-0 self-start mt-6 rounded-full aspect-square w-[50px]" />
                      <div className="flex z-10 flex-col grow shrink-0 basis-0 w-fit">
                        <div className="self-start ml-4 text-xs font-bold max-md:ml-2.5">Zoe T.</div>
                        <div className="px-4 py-2.5 text-xs bg-white rounded-2xl border border-black border-solid">
                          Good afternoon! We're interested in a chatbot for customer support on our website. It should help customers choose services and collect contact information for follow-ups. How quickly can you set it up?
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {columnIndex === 3 && (
                  <>
                    <p className="z-0 grow shrink text-lg leading-7 w-[264px]">
                      We provide a transparent and secure platform that supports you every step of the way.
                    </p>
                    <div className="flex absolute bottom-10 z-0 flex-col grow shrink rounded-none h-[297px] left-[25px] min-w-[240px] w-[226px]">
                      <div className="flex flex-col max-w-full text-base font-semibold bg-white rounded-2xl border border-black border-solid min-h-[183px] w-[151px]">
                        {['Profile', 'Account Settings', 'Log Out', 'Help Center'].map((item, index) => (
                          <div key={index} className={`flex flex-col justify-center ${index === 3 ? 'items-start' : ''} px-3 py-2 ${index > 0 ? 'mt-1.5' : ''} w-full ${index === 2 ? 'text-zinc-500' : index === 3 ? 'text-white bg-black' : 'bg-white'} ${index === 0 ? 'rounded-2xl' : index === 3 ? '' : 'rounded-md'} ${index === 3 ? 'border border-black border-solid' : ''} max-w-[151px] min-h-[41px]`}>
                            <div className="gap-3 self-stretch">{item}</div>
                          </div>
                        ))}
                      </div>
                      <div className="flex overflow-hidden flex-col px-3 pt-1.5 pb-3.5 mt-4 leading-5 bg-lime-100 rounded-3xl shadow-[0px_4px_20px_rgba(0,0,0,0.25)]">
                        <div className="self-start ml-4 text-xs font-bold max-md:ml-2.5">Help Center</div>
                        <div className="px-4 py-3.5 text-xs bg-white rounded-2xl border border-black border-solid">
                          Hello! Please describe your situation, and our specialist will contact you shortly.
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AICommunity;