export const WP_CONFIG = {
  BASE_URL: 'https://engine.aibyhumans.com/wp-json/wp/v2',
  ENDPOINTS: {
    POSTS: '/posts',
    CATEGORIES: '/categories',
    TAGS: '/tags',
    MEDIA: '/media',
    SEARCH: '/search',
  },
  PARAMS: {
    EMBED: '_embed',
    PER_PAGE: 'per_page',
    PAGE: 'page',
    SEARCH: 'search',
    CATEGORIES: 'categories',
    TAGS: 'tags',
  }
}; 