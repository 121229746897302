import React from 'react';

const HomepageHero: React.FC = () => {
  return (
    <section className="px-12 mt-1.5 w-full rounded-3xl bg-zinc-800 max-w-[1410px] max-md:px-5 max-md:max-w-full">
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col w-[66%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col mt-12 text-white max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <h1 className="flex flex-col pr-20 pb-3 w-full text-7xl rounded-sm leading-[76px] max-md:pr-5 max-md:max-w-full max-md:text-4xl max-md:leading-10">
                <span className="z-10 self-start bg-blend-normal max-md:max-w-full max-md:text-4xl max-md:leading-10">
                  Connect with Top AI Experts to Transform Your Business
                </span>
                <div className="flex shrink-0 self-center -mt-5 ml-20 w-2 h-2 bg-gray-100 rounded-sm" />
              </h1>
              <p className="mt-8 text-lg leading-6 bg-blend-normal max-md:max-w-full">
                We provide businesses with leading specialists in the field of artificial intelligence for solutions ranging from AI chatbots and virtual assistants to video editing and beyond.
              </p>
            </div>
            <div className="flex flex-col mt-12 max-w-full w-[175px] max-md:mt-10">
              <button className="overflow-hidden gap-2 self-stretch px-6 py-4 w-full text-lg font-medium leading-none rounded-xl min-h-[56px] max-md:px-5">
                Get Full Access
              </button>
              <p className="mt-2.5 text-sm leading-none bg-blend-normal">
                get a database of experts
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-[34%] max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow max-md:mt-10">
            <div className="flex shrink-0 rounded-xl bg-neutral-600 h-[17px]" />
            {[1, 2, 3].map((index) => (
              <div key={index} className="flex flex-col px-6 pt-3.5 pb-6 mt-5 w-full rounded-xl bg-neutral-600 max-md:px-5">
                <div className="flex gap-3.5 self-start text-white">
                  <img loading="lazy" src={`http://b.io/ext_${index}-`} alt={`Expert ${index}`} className="object-contain shrink-0 aspect-square w-[54px]" />
                  <div className="flex flex-col my-auto">
                    <div className="self-start text-xl font-semibold tracking-wide leading-snug">
                      {index === 1 ? 'Tom A.' : index === 2 ? 'Mary I.' : 'Ricky S.'}
                    </div>
                    <div className="flex gap-1 text-xs leading-loose whitespace-nowrap">
                      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/70a40c9ff3252c2253a05e3f8d716ec8ec83134c650208470d23b0c78421ba17?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="Star rating" className="object-contain shrink-0 my-auto w-4 aspect-square" />
                      <div>{index === 1 ? '4.42' : index === 2 ? '4.46' : '4.85'}</div>
                      <div>({index === 1 ? '25' : index === 2 ? '256' : '87'})</div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col self-end mt-3.5 max-w-full w-[270px]">
                  <p className="text-sm leading-5 text-white">
                    I will fine tune a large language model for your specific needs
                  </p>
                  <div className="flex gap-1.5 mt-4 max-w-full text-xs leading-loose text-center text-zinc-800 w-[196px]">
                    <div className="flex relative gap-2.5 justify-center items-start px-3 py-1 min-h-[28px]">
                      <div className="flex absolute inset-0 z-0 shrink-0 self-start h-7 bg-gray-100 rounded-2xl w-[83px]" />
                      <div className="z-0 my-auto">AI chatbot</div>
                    </div>
                    <div className="flex relative gap-2.5 justify-center items-start px-3 py-1 min-h-[28px]">
                      <div className="flex absolute inset-0 z-0 shrink-0 self-start h-7 bg-gray-100 rounded-2xl w-[107px]" />
                      <div className="z-0 my-auto">AI applications</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="flex flex-col items-start px-6 pt-3.5 mt-5 rounded-xl bg-neutral-600 max-md:px-5">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/78749fe2f6d24633bc343f56dc548523/dcfa1b434b85e5c7f043d27a5f6bfe3e32588ab6ab3a5e716637b6ea7d0eb543?apiKey=78749fe2f6d24633bc343f56dc548523&" alt="Decorative element" className="object-contain aspect-[13.51] w-[54px]" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomepageHero;